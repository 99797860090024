/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { InquiryToCustomerStatus } from "../../../types/Entities/InquiryToCustomer";
import { queryKeys } from "../../../services/reactQuery/reactQueryService";
import * as messagesActions from "../../../store/messages/actions";
import { getCaseLink } from "../../../services/productService";
import { useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import useProductParams from "../../../hooks/useProductParams";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { useCallback } from "react";

export const useInvalidateOpenInquiry = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { productName, productId } = useProductParams();

  return useCallback(
    async (product: AbstractCase) => {
      const inquiryFilters = {
        productId: productId,
        "status[0]": InquiryToCustomerStatus.STATUS_OPEN,
        "status[1]": InquiryToCustomerStatus.STATUS_OVERDUE,
      };
      const inquiryApiUri = "/inquiry_to_customers";
      await queryClient.invalidateQueries(queryKeys.collection(inquiryApiUri, inquiryFilters));
      messagesActions.addMessage({
        type: "success",
        text: "Rückmeldung erfolgreich abgegeben",
      });
      navigate(getCaseLink(product) + "/anfrage/beantwortet");
    },
    [navigate, queryClient, productName, productId]
  );
};
